html {
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    text-align: center;
}

html, body{
    height: 100%;
    margin: 0;
    padding: 0;
}

.Name-wrapper{
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

h2{
    text-align: center;
    margin-top: 10%;
    font-size: 450%;
    letter-spacing: 10px;
    color: #fff;
}

.page {
    height: 100%;
    width: 100%;
    position: absolute;
}

.login.page{
    background-color: #EBBC25;
}

.form{
    height: 100px;
    margin-top: -15%;
    position: relative;
    text-align: center;
    top: 15%;
    /* width: 100%; */
}

.usrinput{
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #fff;
    outline: none;
    padding-bottom: 12px;
    text-align: center;
    font-size: 200%;
}

.confirm-button{
    background: #3498db;
    padding: 5px;
    display: inline-block;
    margin-top: 5%;
    border-radius: 3px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    list-style-type:none;
}

.confirm{
    padding: 0;
    width: 100%;
}

p{
    margin: 0 auto;
    text-align: center;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 28px;
}

