body{
    background-color: #EBBC25;
}

.join-box{
    border: 2px solid black;
    background-color: rgba(236, 236, 236, 0.9);
    border-radius: 5px;
    margin: 0 auto;
    width: 65%;
    position: fixed;
    bottom: 20%;
    left: 14%;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 400;
    padding: 20px;
    font-size: 18px;
    text-align: center;
    padding-top: 10%;
}

.roomcode{
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #fff;
    outline: none;
    text-align: center;
    font-size: 150%;
}