html {
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    text-align: center;
}

html, body{
    height: 100%;
    margin: 0;
    padding: 0;
}

.Room-wrapper{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

h2{
    text-align: center;
    font-size: 400%;
    letter-spacing: 10px;
    color: #fff;
}

.room.select{
    background-color: #EBBC25;
}

.select{
    height: 100%;
    width: 100%;
    position: absolute;
}

.join-create{
    display: inline-block;
    width: 50%;
    margin: 0;
    padding: 0;
}

.joinroom-button{
    background: #3498db;
    padding: 3px;
    display: inline-block;
    border-radius: 3px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    list-style-type:none;
}

.createroom-button{
    background: #3498db;
    padding: 3px;
    display: inline-block;
    margin-left: 5%;
    margin-top: 15%;
    border-radius: 3px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    list-style-type:none;
}

p{
    margin: 0 auto;
    text-align: center;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 28px;
}

.username{
    color: #fff;
    font-size: 150%;
    font-weight: 500;
}