
html {
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    text-align: center;
}

html, body{
    height: 100%;
    margin: 0;
    padding: 0;
}

.Name-wrapper{
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

h2{
    text-align: center;
    margin-top: 10%;
    font-size: 450%;
    letter-spacing: 10px;
    color: #fff;
}

.page {
    height: 100%;
    width: 100%;
    position: absolute;
}

.login.page{
    background-color: #EBBC25;
}

.form{
    height: 100px;
    margin-top: -15%;
    position: relative;
    text-align: center;
    top: 15%;
    /* width: 100%; */
}

.usrinput{
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #fff;
    outline: none;
    padding-bottom: 12px;
    text-align: center;
    font-size: 200%;
}

.confirm-button{
    background: #3498db;
    padding: 5px;
    display: inline-block;
    margin-top: 5%;
    border-radius: 3px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    list-style-type:none;
}

.confirm{
    padding: 0;
    width: 100%;
}

p{
    margin: 0 auto;
    text-align: center;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 28px;
}


html {
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    text-align: center;
}

html, body{
    height: 100%;
    margin: 0;
    padding: 0;
}

.Room-wrapper{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

h2{
    text-align: center;
    font-size: 400%;
    letter-spacing: 10px;
    color: #fff;
}

.room.select{
    background-color: #EBBC25;
}

.select{
    height: 100%;
    width: 100%;
    position: absolute;
}

.join-create{
    display: inline-block;
    width: 50%;
    margin: 0;
    padding: 0;
}

.joinroom-button{
    background: #3498db;
    padding: 3px;
    display: inline-block;
    border-radius: 3px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    list-style-type:none;
}

.createroom-button{
    background: #3498db;
    padding: 3px;
    display: inline-block;
    margin-left: 5%;
    margin-top: 15%;
    border-radius: 3px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    list-style-type:none;
}

p{
    margin: 0 auto;
    text-align: center;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 28px;
}

.username{
    color: #fff;
    font-size: 150%;
    font-weight: 500;
}
.create-wrapper{
    padding: 2%;

}

.roomid{
    font-size: 120%;
    font-weight: 500;
    text-align: left;
}

.player{
    text-align: center;
    padding-top: 12%;
}

.player li{
    border: 2px solid black;
    border-radius: 5px 5px 5px 5px;
    background-color: #A7A7A7;
    padding: 10px;
    display: inline-block;
    font-size: 180%;
    font-weight: 500;
    margin-left: 4%;
    color:#ffff;
}

ul{
    padding: 0;
}

.start-button{
    background: #46D127;
    padding: 5px;
    display: inline-block;
    margin-top: 5%;
    border-radius: 3px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    list-style-type:none;
}

.wait-button{
    background: red;
    padding: 5px;
    display: inline-block;
    margin-top: 5%;
    border-radius: 3px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    list-style-type:none;
}

html {
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    text-align: center;
}

html, body{
    height: 100%;
    margin: 0;
    padding: 0;
}

.Tutorial-wrapper{
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #EBBC25;
    position: absolute;
}

.dialog-box{
    border: 2px solid black;
    background-color: rgba(236, 236, 236, 0.9);
    border-radius: 5px;
    margin: 0 auto;
    width: 65%;
    position: fixed;
    bottom: 20%;
    left: 8%;
    letter-spacing: 2px;
    font-weight: 400;
    padding: 20px;
    font-size: 18px;
    text-align: start;
}

.nav-icon{
    position: absolute;
	font-size: 40px;
    left: 92%;
    bottom: -3%;
}

.dialog-title{
    font-size: 30px;
    position: absolute;
    font-weight: 600;
    left: 40%;
    bottom: 102%;
}

.item-title{
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

.npc{
    width: 15%;
    position: fixed;
    top: 52%;
    right: 5%;
}

.pic{
    width: 20%;
    display: inline-block;
    margin-left: 15%;
    vertical-align: middle;
}

.text{
    display: inline-block;
    width: 50%;
    padding-left: 30px;
    padding-top: 30px;
}

.animalpic, .animal2pic{
    width: 20%;
    text-align: center;
    display: inline-block;
    margin-left: 20%;
}

.animal, .animal2{
    display: inline-block;
    margin-left: 25%;
}

.extraText{
    text-align: center;
    padding-top: 10px;
}

.money-wrapper{
    text-align: left;
    padding-top: 20px;
    display: inline-block;
    width: 20%;
}

.money{
    width: 20%;
    vertical-align: bottom;
}

span{
    font-size: 120%;
    font-weight: 400;
    padding-left: 10px;
}

.purchase-title{
    font-size: 150%;
    font-weight: 400;
    display: inline-block;
}

.item-list{
    display: inline-block;
    width: 50%;
    margin-top: 20px;
}

.item-list li{
    display: inline-block;
    width: 20%;
    vertical-align: middle;
}

.item{
    width: 60%;
}

.item-name{
    text-align: center;
    font-size: 150%;
    font-weight: 400;
}

.item-num{
    font-size: 150%;
    font-weight: 400;
}

.item-button{
    font-size: 100%;
    font-weight: 400;
    border: 3px solid black;
    border-radius: 5px 5px 5px 5px;
    background-color: #D26406;
    color: #fff;
    padding: 5px;
}

ul{
    margin: 0;
}

.startgame-button{
    font-weight: 400;
    font-size: 150%;
    border: 3px solid black;
    border-radius: 5px 5px 5px 5px;
    background-color: #41171A;
    color: #fff;
    display: inline-block;
    padding: 5px;
    margin-top: 2%;
}

.length{
    font-size: 50%;
    padding-left: 5px;
}
.Action-wrapper{
}

.opacity-black{
    position: fixed;
    background-color: rgba(200, 200, 200, 0.6);
    width: 100%;
    height: 100%;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    top: 0;
    left: 0;
}

.action-box{
    font-weight: 500;
    border: 4px solid #000;
    width: 50%;
    border-collapse: collapse;
    background-color: #D26406;
    position: absolute;
    top: 30%;
    left: 25%;
}

.action-box td{
    border: 2px solid black;
    padding: 5px;
    font-size: 150%;
    width: 30%;
}

.action-intro{
    font-size: 180%;
    font-weight: 500;
    color: #ffffff;
    padding-top: 50px;
}

.bomb{
    position: absolute;
    width: 18%;
    left: 2%;
    top: 3%
}

.action-feed{
    width: 25%;
}

.action-sex{
    width: 40%;
}

.action-buy{
    width: 30%;
}

.action-sell{
    width: 40%;
}
.seasontime{
    width: 80%;
}

.deco{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0.6;
    width: 100%;
    height: 36%;
    top: 32%;
    z-index: -1;
}

.seasontext{
    position: absolute;
    color: #FFDBDB;
    font-size: 250%;
    top: 42%;
    left: 38%;
}
body{
    background-color: #EBBC25;
}

.join-box{
    border: 2px solid black;
    background-color: rgba(236, 236, 236, 0.9);
    border-radius: 5px;
    margin: 0 auto;
    width: 65%;
    position: fixed;
    bottom: 20%;
    left: 14%;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 400;
    padding: 20px;
    font-size: 18px;
    text-align: center;
    padding-top: 10%;
}

.roomcode{
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #fff;
    outline: none;
    text-align: center;
    font-size: 150%;
}
.choose{
    font-size: 150%;
    font-weight: 400;
    padding-top: 6%;
}

.choose-box{
    border: 2px solid #000;
    border-radius: 5px 5px 5px 5px;
    display: inline-block;
    font-size: 150%;
    font-weight: 400;
    padding: 20px;
    margin-top: 5%;
}

.choose-action{
    width: 16%;
    display: inline-block;
    margin-top: 5%;;
}
.main-money{
    width: 5%;
    vertical-align: bottom;
}

.navbar span{
    font-size: 180%;
    padding-left: 5%;
}

.navbar{
    padding: 16px;
    text-align: left;
}

.main-countdown{
    width: 8%;
    vertical-align: bottom;
}

.farm{
    margin-top: 30px;
    position: relative;
    display: inline-block;
}

.chickhouse td{
    width: 60px;
    height: 60px;
}

.chickhouse{
    background-color: antiquewhite;
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
    z-index: -1;
}

.chickhouse td{
    border: 3px solid black;
    border-radius: 10px;
    -moz-border-radius: 5px;
    padding: 5px;
}

.Smale{
    /* width: 10%; */
    height: 32%;
    position: absolute;
    top: 66%;
    left: 62%;
}

.Mfemale{
    height: 72%;
    position: absolute;
    left: 58%;
    top: -6%;
}

.Bmale{
    height: 100%;
    position: absolute;
    left: 2%;
}

.eggs{
    width: 20%;
    position: absolute;
    top: 65%;
    left: 80%;
}

.chickenlist{
    border: 2px solid black;
    background-color: rgba(236, 236, 236, 0.9);
    border-radius: 5px;
    margin: 0 auto;
    width: 80%;
    position: fixed;
    bottom: 24%;
    left: 7%;
    letter-spacing: 2px;
    font-weight: 400;
    padding: 20px;
    font-size: 18px;
    text-align: center;
}

.opacitybackground{
    position: fixed;
    background-color: rgba(200, 200, 200, 0.6);
    width: 100%;
    height: 100%;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    top: 0;
    left: 0;
}

.status-box{
    border-spacing: 20px;
}

.status-box tr:first-child td{
    text-align: center;
    font-weight: 500;
    font-size: 120%;
}

.status-box td{
    text-align: center;

}

.status-box tr:nth-child(2) td:nth-child(5){
    border: 2px solid #000;
    border-radius: 5px 5px 5px 5px;
    background-color: #D26406;
    color: #fff;
    font-weight: 400;
}

.status-box tr:nth-child(2) td:nth-child(6){
    border: 2px solid #000;
    border-radius: 5px 5px 5px 5px;
    background-color: #D26406;
    color: #fff;
    font-weight: 400;
}

.status-box tr:nth-child(2) td:nth-child(7){
    border: 2px solid #000;
    border-radius: 5px 5px 5px 5px;
    background-color: #D26406;
    color: #fff;
    font-weight: 400;
}

.cross{
    float: right;
}
