.Action-wrapper{
}

.opacity-black{
    position: fixed;
    background-color: rgba(200, 200, 200, 0.6);
    width: 100%;
    height: 100%;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    top: 0;
    left: 0;
}

.action-box{
    font-weight: 500;
    border: 4px solid #000;
    width: 50%;
    border-collapse: collapse;
    background-color: #D26406;
    position: absolute;
    top: 30%;
    left: 25%;
}

.action-box td{
    border: 2px solid black;
    padding: 5px;
    font-size: 150%;
    width: 30%;
}

.action-intro{
    font-size: 180%;
    font-weight: 500;
    color: #ffffff;
    padding-top: 50px;
}

.bomb{
    position: absolute;
    width: 18%;
    left: 2%;
    top: 3%
}

.action-feed{
    width: 25%;
}

.action-sex{
    width: 40%;
}

.action-buy{
    width: 30%;
}

.action-sell{
    width: 40%;
}