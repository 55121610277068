.choose{
    font-size: 150%;
    font-weight: 400;
    padding-top: 6%;
}

.choose-box{
    border: 2px solid #000;
    border-radius: 5px 5px 5px 5px;
    display: inline-block;
    font-size: 150%;
    font-weight: 400;
    padding: 20px;
    margin-top: 5%;
}

.choose-action{
    width: 16%;
    display: inline-block;
    margin-top: 5%;;
}