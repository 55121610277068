.create-wrapper{
    padding: 2%;

}

.roomid{
    font-size: 120%;
    font-weight: 500;
    text-align: left;
}

.player{
    text-align: center;
    padding-top: 12%;
}

.player li{
    border: 2px solid black;
    border-radius: 5px 5px 5px 5px;
    background-color: #A7A7A7;
    padding: 10px;
    display: inline-block;
    font-size: 180%;
    font-weight: 500;
    margin-left: 4%;
    color:#ffff;
}

ul{
    padding: 0;
}

.start-button{
    background: #46D127;
    padding: 5px;
    display: inline-block;
    margin-top: 5%;
    border-radius: 3px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    list-style-type:none;
}

.wait-button{
    background: red;
    padding: 5px;
    display: inline-block;
    margin-top: 5%;
    border-radius: 3px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    list-style-type:none;
}
