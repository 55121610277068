html {
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    text-align: center;
}

html, body{
    height: 100%;
    margin: 0;
    padding: 0;
}

.Tutorial-wrapper{
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #EBBC25;
    position: absolute;
}

.dialog-box{
    border: 2px solid black;
    background-color: rgba(236, 236, 236, 0.9);
    border-radius: 5px;
    margin: 0 auto;
    width: 65%;
    position: fixed;
    bottom: 20%;
    left: 8%;
    letter-spacing: 2px;
    font-weight: 400;
    padding: 20px;
    font-size: 18px;
    text-align: start;
}

.nav-icon{
    position: absolute;
	font-size: 40px;
    left: 92%;
    bottom: -3%;
}

.dialog-title{
    font-size: 30px;
    position: absolute;
    font-weight: 600;
    left: 40%;
    bottom: 102%;
}

.item-title{
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

.npc{
    width: 15%;
    position: fixed;
    top: 52%;
    right: 5%;
}

.pic{
    width: 20%;
    display: inline-block;
    margin-left: 15%;
    vertical-align: middle;
}

.text{
    display: inline-block;
    width: 50%;
    padding-left: 30px;
    padding-top: 30px;
}

.animalpic, .animal2pic{
    width: 20%;
    text-align: center;
    display: inline-block;
    margin-left: 20%;
}

.animal, .animal2{
    display: inline-block;
    margin-left: 25%;
}

.extraText{
    text-align: center;
    padding-top: 10px;
}
